
@import url('https://fonts.cdnfonts.com/css/helios-pro');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto:wght@100&family=Tinos&display=swap');

body,
html {
  background-image: url("./1soon.png");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Helios Pro', sans-serif;
  background-color: black;
}
 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.z1000 {
  color: white;
  text-align: center;
  width: 100%;
  font-family: serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  font-family: 'Roboto', sans-serif;

}

.address-section {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  font-size: 13px;
  margin-bottom: 48px;
}

.address-section .left {
  text-align: right;
  margin-right: 7px;
  max-width: 180px;
}
.address-section .right {
  text-align: left;
  margin-left: 7px;
  max-width: 180px;
}

.coming-soon-text {
  font-size: 40px;
  margin-bottom: 80px;
  font-family: 'Tinos', serif;
}